<template>
  <div class="pt-60 pr-76 pl-44">
    <div class="flex items-center">
      <div class="mr-56">
        <div class="title text-28 font-medium ">直播公开课</div>
        <div class="pt-20 pb-32 text-16 text-color-666">
          直播公开课是一种面向大众的在线直播课程，通常作为引流和转化的用途。讲师、助教和观众可在直播间进行互动，学员可以直接购买商品，也可以邀请好友观看直播。
        </div>

        <a-button class="h-40"
                  type="primary"
                  style="width: 184px;"
                  @click="goToOpen">去开通</a-button>
      </div>

      <img src="@/assets/live-banner.png"
           alt="公开课图片"
           style="width: 378px; ">
    </div>

    <div class="my-72">
      <div class="title-mark pl-12 mb-18">功能介绍</div>

      <div class="py-20 bg-color-f2 bg-opacity-30 border-radius">
        <a-row v-for="(list, index) in funcList"
               :key="index">
          <a-col v-for="func in list"
                 :key="func.title"
                 class="flex items-start pt-32 px-24 pb-24"
                 :span="8">
            <svg-icon class="mr-16 flex-shrink-0"
                      style="font-size: 60px;"
                      :type="func.icon" />
            <div>
              <div class="pb-4 text-16 text-color-333 font-medium">{{ func.title }}</div>
              <span class="text-color-666">{{ func.desc }}</span>
            </div>
          </a-col>
        </a-row>

      </div>
    </div>
  </div>

  <a-modal v-model:visible="remindModalVisible"
           title="绑定提醒"
           width="400px"
           :footer="null">
    <p class="text-color-333">开通直播公开课需要系统绑定公众号。</p>

    <router-link :to="{name: 'system_setting_official_account'}">
      <div class="text-right">
        <a-button type="primary"
                  class="mt-32">去绑定</a-button>
      </div>
    </router-link>
  </a-modal>
</template>

<script>
import { defineComponent, ref, computed } from "vue";
import _ from "lodash";
import { useRouter } from "vue-router";
import { message } from "ant-design-vue";

import corpSettingApi from "@/service/api/corpSetting";
import liveApi from "@/service/api/live";

import SvgIcon from "@/components/SvgIcon";

export default defineComponent({
  name: "LiveOpenClassSetting",

  components: {
    SvgIcon,
  },

  setup() {
    const router = new useRouter();

    // 判断是否开通直播
    const judgeIsOpen = async () => {
      const res = await liveApi.isOpenLiveAccount();

      if (res) {
        router.push({
          name: "liveOpenClass_index",
        });
        return;
      }
    };

    // 功能介绍列表
    const functionDescList = [
      {
        icon: "icondaogou",
        title: "直播带货",
        desc: "观众在观看直播的同时可以直接购买商品。",
      },
      {
        icon: "iconbanji",
        title: "连接网校",
        desc: "商品可以来自网校课程和班级，客户购买后，系统自动创建账户并分配权限。",
      },
      {
        icon: "iconyuangongyeji",
        title: "排行榜",
        desc: "直播间显示邀请排行榜，激励学员主动分享课程。",
      },
      {
        icon: "icona-zhibo11",
        title: "直播互动",
        desc: "用户可在直播间发评论和连麦；讲师、助教可以禁言、踢人、撤回评论。",
      },
    ];

    const funcList = computed(() => {
      return _.chunk(functionDescList, 3);
    });

    // 判断是否开通公众号显示提示框
    const remindModalVisible = ref(false);

    async function goToOpen() {
      const res = await corpSettingApi.getConfigurations();

      if (res.setWechatConf.wechatPlatformStatus === "open") {
        await liveApi.createAccount().catch(() => {
          message.error("直播账号创建失败，请重试");
          return;
        });

        router.push({ name: "system_setting_live" });
        return;
      }

      remindModalVisible.value = true;
    }

    judgeIsOpen();

    return {
      funcList,

      remindModalVisible,
      goToOpen,
    };
  },
});
</script>
<style lang='less' scoped>
.enable-btn {
  position: absolute;
  top: 50px;
  right: 0;
  transform: translateY(-50%);
}
</style>